































































































import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import IconGrade from '@/components/utils/IconGrade.vue'
import {
  discountRate,
  getDateTime,
  phoneByCountryId,
  toCapitalize,
  toReadableNumber,
  sendToApp,
  vipAmountByCountry
} from '@/helpers'
import { useI18n } from 'vue-composable'
import BigNumber from 'bignumber.js'

import type { ISourceCountryIso } from '@/types'

import { IS_LOGGING } from '@/data/constants'

interface State {
  [key: string]: any;
  countryIso: ISourceCountryIso;
}

const {
  state: {
    auth
  },
  getters,
  commit
} = store

const { achievementRate } = getters['auth/gradeForNext']
const date = new Date()
date.setMonth(date.getMonth() - 5)

const startYearMonthDate = getDateTime(date, false, { year: 'numeric', month: '2-digit' })
const endYearMonthDate = getDateTime(new Date(), false, { year: 'numeric', month: '2-digit' })

export default defineComponent({
  components: {
    IconGrade
  },
  setup () {
    const { $t } = useI18n()

    const labels = {
      expectedGradeLabel: getters['auth/expectedGradeLabel'],
      gradeLabelByLocale: getters['auth/gradeLabelByLocale'],
      info: $t('Benefit.title.MyLevelIs.none', {
        name: auth.person.name.full || `${phoneByCountryId(auth.person.phone.code).phone_code}${auth.person.phone.number}`,
        vip_level: `${toCapitalize(getters['auth/gradeLabel'])}`
      }).value,
      sales: $t('Benefit.guide.FeeDiscount.KR', { percent: (new BigNumber(1).minus(auth.person.grade_discount_rate)) * 100 }).value
    }

    const redirectCouponBook = () => {
      const callback = {
        message: $t('Benefit.guide.FormoreCoupons.CompleteVerification').value,
        button: $t('Global.button.Verify.None').value,
        action: {
          name: sendToApp,
          type: 'verifications'
        }
      }
      if (!getters['auth/isVerifyCompleteStatus']) return commit('modal/$OPEN_MODAL', { modalName: 'warning', callback })
      sendToApp('couponBook', { baseUri: location.origin, path: '/coupon-book', title: $t('Benefit.button.ReceivingCoupons.None').value })
    }

    const state = reactive({
      $t,
      grades: auth.grades,
      gradeLabel: getters['auth/gradeLabel'],
      labels,
      accumulatedAmountLast6months: toReadableNumber(auth.person.accumulated_amount),
      achievementRate,
      countryIso: getters['country/$currentSourceCountryCode'],
      currencyIso: getters['country/$currentSourceCurrencyIso'],
      destinationCountryIso: getters['country/$currentDestinationCountryCode'],
      isDomesticSource: getters['country/isDomesticSource'],
      toCapitalize,
      startYearMonthDate,
      endYearMonthDate,
      redirectCouponBook,
      vipMessage () {
        const percent = (grade: keyof typeof discountRate) => {
          return discountRate[grade][state.destinationCountryIso as 'CN'] || discountRate[grade].default
        }
        const isConfirmedNextGrade = auth.person.grade < auth.person.estimated_grade
        const vipAmount = vipAmountByCountry(auth.grades[auth.person.grade + 1], store.getters['country/$currentSourceCountryCode'])
        const isNextGradeSoon = auth.person.accumulated_amount >= vipAmount - store.getters['country/$currentSourceCountryObject'].message_conversion_amount[auth.grades[auth.person.grade + 1]]
        const remainingAmountUntilNextLevel = new BigNumber(vipAmount).minus(auth.person.accumulated_amount) * 1

        if (isConfirmedNextGrade) return $t('Vip.BoxMsg.bz_sz.none', { VipLevel: `<b>${toCapitalize(state.labels.expectedGradeLabel)}</b>` }).value
        if (state.gradeLabel === 'gold' && state.labels.expectedGradeLabel === 'gold') {
          const message = state.isDomesticSource
            ? $t('Vip.BoxMsg.kr_gz.none', { percent: `<b>${percent('gold')}</b>` }).value
            : $t('Vip.BoxMsg.gz.none').value
          return message
        }
        if (isNextGradeSoon) {
          if (state.gradeLabel === 'gold') return $t('Vip.BoxMsg.g2.none', { amount: `<b>${toReadableNumber(remainingAmountUntilNextLevel)}</b>`, currency: ` <b>${state.currencyIso}</b>` }).value
          const nextGrade = auth.grades[auth.person.grade + 1] || 'gold'
          const message = state.isDomesticSource
            ? $t('Vip.BoxMsg.kr_b2.none', { amount: `<b>${toReadableNumber(remainingAmountUntilNextLevel)}</b>`, currency: ` <b>${state.currencyIso}</b>`, percent: `<b>${percent(nextGrade)}</b>` }).value
            : $t('Vip.BoxMsg.b2s2.none', { amount: `<b>${toReadableNumber(remainingAmountUntilNextLevel)}</b>`, currency: ` <b>${state.currencyIso}</b>`, VipLevel: `<b>${toCapitalize(nextGrade)}</b>` }).value
          return message
        }
        if (state.gradeLabel === 'basic') {
          return $t('Vip.BoxMsg.b1.none', { amount: '<b>100</b>', currency: '<b>%</b>' }).value
        }
        const message = !store.getters['country/$currentSourceCountryObject'].fee_discount.coupon[state.gradeLabel]
          ? $t('Vip.BoxMsg.kr_s1g1.none', { VipLevel: `<b>${toCapitalize(state.gradeLabel)}</b>`, amount: `<b>${percent(state.gradeLabel)}</b>`, unit: '<b>%</b>' }).value
          : $t('Vip.BoxMsg.s1g1.none', { VipLevel: `<b>${toCapitalize(state.gradeLabel)}</b>`, amount: `<b>${toReadableNumber(store.getters['country/$currentSourceCountryObject'].fee_discount.coupon[state.gradeLabel].amount * store.getters['country/$currentSourceCountryObject'].fee_discount.coupon[state.gradeLabel].number)}</b>`, currency: `<b>${state.currencyIso}</b>` }).value
        return message
      }
    }) as State

    const useAmplitudeLogging = () => {
      const properties = {
        stage: getters['auth/getStageStatus'],
        vip_level: getters['auth/gradeLabel']
      }

      sendToApp('view_myGrade', properties, IS_LOGGING)
    }

    useAmplitudeLogging()

    return {
      ...toRefs(state)
    }
  }
})
